//atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/3a88c1b4ae52408ba83d5aefd8d42660.png
export const ydList = [
  {
    name: "不限量产品8.8元/天起",
    dataList: [
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/3a88c1b4ae52408ba83d5aefd8d42660.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629&productId=6787#/optionaldestination"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/fe03ccc93f824c63a83a5bf78b163d29.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=YDYLDG&productId=7546&canChangeType=1"
      },
      {
        img: "	https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/b7893d57f4c6450a97f194b81d28a6b9.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=OZDG&productId=7626&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/bd3aeff79584480081c4435e7786eff5.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=RB&productId=2021&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/142c3b364df34f7dbea91998247b497f.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=HG&productId=1930&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/e664472cea0341359dfd139d76fd7eea.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=RCEP&productId=8285&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/8a4ee5cab0294e52a41ff57e5016f79d.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=BMDG&productId=2484&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/b019433191ff4cfbabef2b2145db42a8.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=ALQ&productId=3405&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/ed9e03f4cd98456eac6035f5cf100ee7.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=NMDG&productId=2214&canChangeType=1"
      },{
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/21e39a2b374640fdad218ea8eadf2dff.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=DYZDG&productId=2626&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/666eae0f61ab4e8f8703db745e5cde3f.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=FZDG&productId=2716&canChangeType=1"
      }
    ],
  },
  {
    name: "限量产品",
    dataList: [
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/a8c3674e957143af956aed10a0c62e4d.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=GALL&productId=8542&canChangeType=1",
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/fcea6c77f3014fd0bb986d6a76f15048.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=GALLYY&productId=8563&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/6abccd1530b44f998bc372e73133c04f.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=TWLLYY&productId=9784&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/0c14570884c144efbdd31a81ec080fa8.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=XMTLLYY&productId=9793&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/fc09169e0a4c45328b3c6a6582542119.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=YNLLYY&productId=9912&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/bd05170a2c4444fb84a121a65b812b1a.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=RBLLYY&productId=9803&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/c4b99900f0554a81a00ec0a38c47e19f.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=HGLLYY&productId=9812&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/21b5e6e3374c4b72952fba839419b530.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=YDNXYLLYY&productId=9823&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/68aad1f1c7f04ae9a06c5b0bec97958c.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=ELSLLYY&productId=9839&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/6d5dcbb5e2c642ac83bffe8c6c639a99.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=OZLLYY&productId=9848&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/a182bfd73a17472cb592bf3a61c276e3.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=JPZLLYY&productId=9857&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/c7a4010579684554ab2d6153ec1f84b2.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=ADLYLLYY&productId=9866&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/897f4fe68a3f45178cfd840bc885ce97.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=MGLLYY&productId=9875&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/c44f4efed67b416982056416a478a473.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=ALQLLYY&productId=9884&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/b6d97e922a444a2790b34eb849042506.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=JNDLLYY&productId=9893&canChangeType=1",
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/7b0fe61e2d404f898e21cc01a7083b56.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=Special&CLASS_KEY=XXLLLYY&productId=9902&canChangeType=1"
      },
      {
        img: "https://atv.jegotrip.com.cn/partners/vplus/via-production/resource/activity/0/2024-12-27/4104dd9e190e43f5b935dfe118919cee.png",
        href: "https://cp.jegotrip.com.cn/static/communicationmarket/index.html?vChannel=beijing5018&storeid=92271&vActivityId=4629#/personalityflows?pageType=InternationalRoam&CLASS_KEY=GMYB&productId=7670&canChangeType=1"
      }
    ]
  }
];
