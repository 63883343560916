import Axios, { Method } from "axios";
import { Dialog } from "vant";

const baseURL =
  location.host.includes("test") || location.host.includes("localhost")
    ? "http://test-liulv-card-international-service.liulv.net/pre-release"
    : // ? 'https://liulv-card-international-service.liulv.net'
      // ? 'http://172.16.5.42:31280'
      "https://liulv-card-international-service.liulv.net";
interface Options {
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  mode?: "no-cors" | "cors" | "same-origin";
  cache?: "no-cache" | "reload" | "force-cache" | "only-if-cached";
  credentials?: "include" | "same-origin" | "omit";
  headers?: any;
  data?: any;
}
export async function request(option: Options) {
  const defaultOption: any = {
    method: option?.method || "GET", // *GET, POST, PUT, DELETE, etc.
    // mode: option?.mode || "no-cors", // no-cors, *cors, same-origin
    mode: option?.mode || "cors", // no-cors, *cors, same-origin
    "Access-Control-Allow-Origin": "*",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
    // "Access-Control-Allow-Credentials": true,
    // cache: option?.cache || 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    // headers: {
    //   'Content-Type': 'application/json',
    //   // 'Content-Type': 'application/x-www-form-urlencoded',
    // },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  if (option.method !== "GET") {
    if (option.data) defaultOption.body = JSON.stringify(option.data);
  }
  const response = await fetch(baseURL + option.url, { ...defaultOption });

  const res = await response.json();
  if (res.code === 401) {
    await Dialog.alert({ title: "提示", message: "请重新登录" });
    location.replace("/login");
    return;
  }

  return res; // parses JSON response into native JavaScript objects
}

export default async function (option: Options) {
  if (window.fetch && typeof window.fetch === "function") {
    return request(option).then((res) => {
      if (res.code === 401) {
        return Dialog.alert({ title: "提示", message: res.message }).then(
          () => {
            location.replace("/login");
          }
        );
      }
      return res;
    });
  } else {
    const axios = Axios.create({
      baseURL,
      timeout: 20000, // 请求超时 20s
    });
    return axios(option)
      .then((res: any) => {
        if (res.code === 401) {
          return Dialog.alert({ title: "提示", message: res.message }).then(
            () => {
              location.replace("/login");
            }
          );
        }
        return res;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
